<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 ">
                <h4 class="title-in-h">{{$t('rate.section.rates_restrict')}}</h4>
                <breadcrumb>
                    <breadcrumb-item v-for="breadcrumb in breadcrumbs" v-if="breadcrumb.type!=='active'">
                        <router-link :to="{path:breadcrumb.path}"  class="color-blue-var" >{{breadcrumb.name}}</router-link>
                    </breadcrumb-item>
                    <breadcrumb-item active v-else>
                        {{breadcrumb.name}}
                    </breadcrumb-item>
                </breadcrumb>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <card class="mt-2"  v-loading="screenLoading"
                      element-loading-lock="true"
                      :element-loading-text="screenText"
                      element-loading-spinner="el-icon-loading">
                    <div>
                        <div class="col-sm-12">
                            <div class="box-inventory ">
                                <h4 class="is_title">Puede realizar cambios para un periodo máximo de 2 año apartir de
                                    la fecha de hoy.</h4>

                                <div class="form-preview w-border" v-show="showBox([2, 3, 4])">
                                    <label class="l-title">Seleccionar fechas</label>
                                    <span class="brl text-normal text-capitalize">{{calendar_info.min}} - {{calendar_info.max}}</span>
                                    <span class="brl text-normal">Se actualizarán {{calendar_info.selected.length}} {{calendar_info.selected.length===1?'día':'días'}} </span>
                                    <a class="a-right r-20 type-button"> <i class="fa fa-check"></i></a>
                                    <a class="a-right type-text" @click="info_page.index_page=1"> Editar</a>
                                </div>

                                <div class="form-preview w-border" v-show="showBox([3, 4])" v-if="dataRateconfigs.length">
                                    <label class="l-title">Contratos</label>
                                    <div v-for="room in dataRateconfigs[0].dataRooms"  >

                                        <label class="font-weight-bold mb-1">{{room.room_name}}</label>
                                        <div v-for="rateplan in room.rateplans">
                                            <label class="mb-0"><span class="fa fa-check mr-2"> </span>{{rateplan.rateplan_name}} </label>
                                        </div>

                                    </div>
                                    <a class="a-right r-20 type-button"> <i class="fa fa-check"></i></a>
                                    <a class="a-right type-text"  @click="info_page.index_page=2"> Editar</a>
                                </div>

                                <div class="form-dates w-border" v-show="info_page.index_page===1">
                                    <div class="box-dates  mt-3">
                                        <div class="row ">
                                            <div class="col-md-7">
                                                <label class="subtitle">Seleccionar fechas</label>
                                                <fg-input>
                                                    <el-date-picker v-model="inventory.date_range" type="daterange"
                                                                    placeholder="Inicio"
                                                                    format="dd-MM-yyyy"
                                                                    :picker-options="pickerOptions1"
                                                                    range-separator="a"
                                                                    start-placeholder="Fecha inicio"
                                                                    end-placeholder="Fecha fin"
                                                    >
                                                    </el-date-picker>
                                                </fg-input>
                                            </div>

                                            <div class="col-md-12">
                                                <div class="box-days">
                                                    <div class="form-check">
                                                        <label for="lunes_d" class="form-check-label check-admin">
                                                            <input class="form-check-input" type="checkbox" id="lunes_d"
                                                                   v-model="inventory.week[1]">
                                                            <span class="form-check-sign"></span>
                                                            Lun.
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label for="martes_d" class="form-check-label check-admin">
                                                            <input class="form-check-input" type="checkbox"
                                                                   id="martes_d" v-model="inventory.week[2]">
                                                            <span class="form-check-sign"></span>
                                                            Mar.
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label for="miercoles_d" class="form-check-label check-admin">
                                                            <input class="form-check-input" type="checkbox"
                                                                   id="miercoles_d" v-model="inventory.week[3]">
                                                            <span class="form-check-sign"></span>
                                                            Mie.
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label for="jueves_d" class="form-check-label check-admin">
                                                            <input class="form-check-input" type="checkbox"
                                                                   id="jueves_d" v-model="inventory.week[4]">
                                                            <span class="form-check-sign"></span>
                                                            Jue.
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label for="viernes_d" class="form-check-label check-admin">
                                                            <input class="form-check-input" type="checkbox"
                                                                   id="viernes_d" v-model="inventory.week[5]">
                                                            <span class="form-check-sign"></span>
                                                            Vie.
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label for="sabado_d" class="form-check-label check-admin">
                                                            <input class="form-check-input" type="checkbox"
                                                                   id="sabado_d" v-model="inventory.week[6]">
                                                            <span class="form-check-sign"></span>
                                                            Sab.
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <label for="domingo_d" class="form-check-label check-admin">
                                                            <input class="form-check-input" type="checkbox"
                                                                   id="domingo_d" v-model="inventory.week[0]">
                                                            <span class="form-check-sign"></span>
                                                            Dom.
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-4">
                                                <div class="box-button mb-5 mt-3">
                                                    <button type="button" class="add-item btn-default" @click="addRangeCalendar">
                                                        Añadir al calendario
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="dates-manually">
                                        <div class="row">
                                            <label class="l-title">Ajustes manuales</label>
                                            <label class="item-year text-center color-blue-var year-header mb-3">{{calendar_info.yearHead}}</label>

                                            <div class="box-calendar">


                                                <div class="item-month"
                                                     v-for="(month_s, idsx) in calendar_info.monthsHead">
                                                    <div class="line-week lw-head">
                                                        <span class="fa fa-chevron-left sp-icon"
                                                              :class="(info_page.btn_back_status)?'':'n-visible'"
                                                              v-if="idsx===0" @click="updDate('minus')"></span>
                                                        <label class="calendar-title wout-icon  color-blue-var"
                                                               :class="checkClass(idsx)" @click="addMonth(month_s)">{{nameMonth(month_s)}}</label>
                                                        <span class="fa fa-chevron-right sp-icon" v-if="idsx===2"
                                                              @click="updDate('plus')"></span>
                                                    </div>


                                                    <div class="line-week lw-head color-blue-var">
                                                        <div class="item-7" data-key="0">D</div>
                                                        <div class="item-7" data-key="1">L</div>
                                                        <div class="item-7" data-key="2">M</div>
                                                        <div class="item-7" data-key="3">M</div>
                                                        <div class="item-7" data-key="4">J</div>
                                                        <div class="item-7" data-key="5">V</div>
                                                        <div class="item-7" data-key="6">S</div>
                                                    </div>

                                                    <div class="line-week" v-for="day_s in checkMonth(month_s)">
                                                        <div class="item-7" @click.capture="addCalendar(day_s[0])"><span
                                                                :class="checkDay(day_s[0])">{{dayCalendar(day_s[0])}}</span>
                                                        </div>
                                                        <div class="item-7" @click="addCalendar(day_s[1])"><span
                                                                :class="checkDay(day_s[1])">{{dayCalendar(day_s[1])}}</span>
                                                        </div>
                                                        <div class="item-7" @click="addCalendar(day_s[2])"><span
                                                                :class="checkDay(day_s[2])">{{dayCalendar(day_s[2])}}</span>
                                                        </div>
                                                        <div class="item-7" @click="addCalendar(day_s[3])"><span
                                                                :class="checkDay(day_s[3])">{{dayCalendar(day_s[3])}}</span>
                                                        </div>
                                                        <div class="item-7" @click="addCalendar(day_s[4])"><span
                                                                :class="checkDay(day_s[4])">{{dayCalendar(day_s[4])}}</span>
                                                        </div>
                                                        <div class="item-7" @click="addCalendar(day_s[5])"><span
                                                                :class="checkDay(day_s[5])">{{dayCalendar(day_s[5])}}</span>
                                                        </div>
                                                        <div class="item-7" @click="addCalendar(day_s[6])"><span
                                                                :class="checkDay(day_s[6])">{{dayCalendar(day_s[6])}}</span>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                            <div class="col-md-12">
                                                <div class="box-legends">
                                                    <label>
                                                        <span class="example-picker"></span>Fechas seleccionadas
                                                    </label>
                                                    <label class="right-t color-blue" @click="resetCalendar">
                                                        Restablecer
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="box-button mb-4">
                                                    <button type="button" class="add-item btn-default"
                                                            :class="info_page.buttons.page_1?'':'inactive'"
                                                            @click="orderDate">Siguiente
                                                    </button>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                                <div class="form-preview w-border" v-show="info_page.index_page===1">
                                    <label class="mt-3 l-title">Seleccionar actualizaciones</label>
                                </div>

                                <div class="form-updates w-border" v-show="info_page.index_page===2">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="head  mt-3">
                                                <label class="subtitle">Seleccionar contratos</label>
                                            </div>
                                        </div>

                                        <div class="col-md-12">
                                            <div class="form-check mt-3">
                                                <label for="all_types" class="form-check-label">
                                                    <input class="form-check-input" type="checkbox" id="all_types"
                                                           v-model="info_page.all_types"
                                                           @change="changeTypeFilter('all')">
                                                    <span class="form-check-sign"></span>Seleccionar todos los contratos

                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row pl-4">
                                        <div class="col-md-4 pr-0" v-for="type_ in info_page.contract_types"
                                             v-if="type_.status">
                                            <div class="form-check">
                                                <label :for="type_._id" class="form-check-label">

                                                    <input class="form-check-input" type="checkbox" :id="type_._id"
                                                           :value="type_._id" v-model="type_.filter"
                                                           @change="changeTypeFilter('item', type_._id, type_.filter)">
                                                    <span class="form-check-sign"></span>{{type_.name}}

                                                </label>

                                                <label :for="type_._id+'r'" class="form-check-label ml-4" v-if="type_.showLinks">

                                                    <input class="form-check-input" type="checkbox" :id="type_._id+'r'"
                                                           :value="type_._id+'-vinc'" v-model="type_.activeLinks" :disabled="!type_.filter"
                                                           @change="changeTypeFilter('item', type_._id+'-vinc', type_.filter)">
                                                    <span class="form-check-sign"></span><span :class="type_.filter?'':'c-disabled'" v-html="info_page.links_rateplans"></span>

                                                </label>
                                            </div>
                                        </div>



                                    </div>

                                    <div class="row">
                                        <div class="col-md-12" v-for="(room, idx) in inventory.rooms" v-if="room.isShow">
                                            <div class="group-upd color-primary">
                                                <label>
                                                    <a v-tooltip.top-center="formatText(room.isBaseLink)"  v-if="room.isBaseLink.length"
                                                    ><span class="fa fa-star"> </span></a>


                                                    {{room.name}}
                                                </label>
                                            </div>
                                            <div class="form-check mb-0" v-for="rateplan in room.rateplans" >
                                                <label :for="rateplan._id+idx" class="form-check-label"
                                                       v-if="rateplan.isShow">
                                                    <input class="form-check-input" type="checkbox"
                                                           :id="rateplan._id+idx" v-model="rateplan.isModified"
                                                           @change="checkContractModified()">
                                                    <span class="form-check-sign"></span>{{rateplan.name}}

                                                    <a v-tooltip.top-center="'Contrato con habitaciones vinculadas.'"  v-if="rateplan.isLinked.length"
                                                    ><i class="fa fa-link"></i></a>

                                                </label>


                                                <!-- <label :for="'contractb_'+idx" class="form-check-label ml-3" v-for="link_rate in rateplan.contractLink">
                                                     <input class="form-check-input" type="checkbox"  :id="'contractb_'+idx"  v-model="link_rate.mealPlanStatus"  @change="checkContractModified()">
                                                     <span class="form-check-sign" ></span>{{link_rate.name}} <span class="fa fa-link"></span>
                                                 </label>-->


                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="box-button mb-4 mt-4">
                                                <button type="button" class="add-item btn-default"
                                                        :class="info_page.buttons.page_2?'':'inactive'"
                                                        @click="nextActions()">Siguiente
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-updates w-border" v-show="info_page.index_page===3">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="head  mt-3">
                                                <label class="subtitle">Seleccionar actualizaciones</label>
                                                <label>
                                                    No se realizarán cambios en los campos en blanco
                                                </label>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="group-upd" >
                                                <label>
                                                    ¿Qué le gustaría hacer?
                                                </label>
                                            </div>
                                            <div class="form-check"  >
                                                <label for="upd_rate" class="form-check-label">
                                                    <input class="form-check-input" type="checkbox"  id="upd_rate"  v-model="filter_page.upd_rates">
                                                    <span class="form-check-sign" ></span>Actualizar tarifas
                                                </label>
                                            </div>
                                            <!--<div class="form-check pl-3"  >
                                                <label for="upd_restricts" class="form-check-label">
                                                    <input class="form-check-input" type="checkbox"  id="upd_restricts"  v-model="filter_page.upd_restricts">
                                                    <span class="form-check-sign" ></span>Actualizar restricciones
                                                </label>
                                            </div>-->
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="group-upd" >
                                                <label>
                                                    ¿Qué días?
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <fg-input @change.native="changeDays()" v-model="filter_page.always_days" >
                                                    <radio label="1" v-model="filter_page.always_days" value="all_days"> Todos los días</radio>
                                                    <radio label="2" v-model="filter_page.always_days" value="specific" >Por día de la semana</radio>
                                                </fg-input>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="group-upd" >
                                                <label>
                                                    Tipo de tarifa
                                                </label>
                                            </div>
                                            <div class="form-check"  >
                                                <fg-input>
                                                    <!--<radio label="1" v-model="filter_page.links_rates" v-if="contracts.vinculatedStatus">Tarifas vinculadas</radio>-->
                                                    <radio label="2" v-model="filter_page.links_rates">Tarifas en especifico</radio>
                                                </fg-input>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" >
                                        <div class="col-md-12">
                                            <div class="box-item"  v-for="(configRate, configIdx) in dataRateconfigs" :key="configIdx">
                                                <h5 class="font-weight-bold" v-show="configRate.day_number!=='-1'">{{info_page.daysName[configRate.day_number]}}</h5>

                                                <div class="box-body pt-40" v-for="(room, idx_) in configRate.dataRooms" :key="idx_">

                                                    <label class="font-weight-bold mb-0" >{{room.room_name}}</label>


                                                    <div class="box-rate mb-2 box-ai"   v-for="(rateplan, cnt_idx) in room.rateplans"  :key="cnt_idx">

                                                        <div class="row">
                                                            <div class="col-12">
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-4 pr-0">
                                                                <label class="mb-0 mt-25"><span class="fa fa-star" v-show="rateplan.hasRoomLink"></span>{{rateplan.rateplan_name}}</label>
                                                            </div>
                                                            <div class="col-4 pr-0">
                                                                <div class="item-form mid" v-show="rateplan.rates_list.length">
                                                                    <label class="mb-0">L. de tarifas</label>
                                                                    <el-select class="select-default"  size="large" v-model="rateplan.rate_choose"
                                                                               @change="changeRateChoose(rateplan.rate_choose, configIdx, idx_, cnt_idx); changeBar($event, rateplan)" v-if="rateplan.select_editable">
                                                                        <el-option v-for="(option, idxKe) in rateplan.rates_list" :value="option.value"
                                                                                   :label="option.name">
                                                                        </el-option>
                                                                    </el-select>
                                                                    <el-select class="select-default class-disabled"  size="large" v-model="selectDefault" disabled="disabled"
                                                                               v-else>
                                                                        <el-option v-for="(option, idxKe) in rateplan.rates_list" :value="option.value"
                                                                                   :label="option.name">
                                                                        </el-option>
                                                                    </el-select>

                                                                </div>
                                                                <div class="item-form mid">
                                                                    <label class="mb-0"  v-if="rateplan.meal_plan<3">Tarifa base</label>
                                                                    <label class="mb-0" v-else>Doble</label>
                                                                    <fg-input
                                                                            type="number"
                                                                            class="input-default"
                                                                            placeholder=""
                                                                            :disabled="!rateplan.editable"
                                                                            @input="checkChanges()"
                                                                            v-model="rateplan.ratesnInfo.base_rate">
                                                                    </fg-input>
                                                                </div>
                                                                <!--<label class="mb-0 mt-2">Resultado:  $  {{formatMoney(rateplan.double.value)}}</label>-->
                                                            </div>
                                                            <div class="col-4" v-if="rateplan.meal_plan<3">
                                                                <div class="item-form mid">
                                                                    <label class="mb-0">Tarifa base</label><br>
                                                                    <label class="lh-40" >$  {{formatMoney(rateplan.ratesnInfo.base_rate)}}</label>
                                                                </div>
                                                                <div class="item-form mid">
                                                                    <label class="mb-0">Extra pax</label><br>
                                                                    <label class="lh-40" >$  {{gapOnly(rateplan.ratesnInfo.base_rate, rateplan.ratesnInfo.extra_pax.value, (rateplan.ratesnInfo.extra_pax.link)?rateplan.ratesnInfo.extra_pax.link:'=')}}</label>
                                                                </div>
                                                            </div>
                                                            <div class="col-12" v-if="rateplan.meal_plan===3">
                                                                <div class="row  row-css" :class="calculateSize(room, rateplan.ratesnInfo)">
                                                                    <div class="col-12"><label class="mb-0 font-weight-bold"> Resultado tarifas</label></div>

                                                                    <div class="col-2 size-12" v-for="(assing, idx) in rateplan.ratesnInfo.assignments" v-if="assing.cant!==2">
                                                                        <label class="mb-0">
                                                                            {{info_page.info_links.title.extendible[assing.cant]}} <br>
                                                                            <span>  $  {{gapOnly(rateplan.ratesnInfo.base_rate, assing.value, (assing.link)?assing.link:'=')}}</span>
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-2 size-12"  v-else>
                                                                        <label class="mb-0">
                                                                            {{info_page.info_links.title.extendible[2]}} <br>
                                                                            <span>  $  {{formatMoney(rateplan.ratesnInfo.base_rate)}}</span>
                                                                        </label>
                                                                    </div>
                                                                    <div class="col-2 size-12" v-if="rateplan.ratesnInfo.jr && room.max_jr>0">
                                                                        <label class="mb-0">
                                                                            Juniors <br>
                                                                            <span>  $  {{gapOnly(rateplan.ratesnInfo.base_rate, rateplan.ratesnInfo.jr.value, (rateplan.ratesnInfo.jr.link)?rateplan.ratesnInfo.jr.link:'=')}}</span>
                                                                        </label>

                                                                    </div>

                                                                    <div class="col-2 size-12" v-if="rateplan.ratesnInfo.children && room.max_children>0 ">
                                                                        <label class="mb-0">
                                                                            Niños <br>
                                                                            <span>  $  {{gapOnly(rateplan.ratesnInfo.base_rate, rateplan.ratesnInfo.children.value, (rateplan.ratesnInfo.children.link)?rateplan.ratesnInfo.children.link:'=')}}</span>
                                                                        </label>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>



                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="box-button mb-4 mt-4">
                                                <button type="button" class="add-item btn-default"  :class="info_page.buttons.page_3?'':'inactive'" @click="toActualizaciones()">Vista previa de actualizaciones</button>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div class="form-preview w-border form-resume" v-show="showBox([4])">
                                    <label class="l-title">Actualizaciones</label>

                                    <div class="box-item"  v-for="(configRate, configIdx) in dataRateconfigs">

                                        <label class="font-weight-bold" v-show="configRate.day_number!=='-1'">{{info_page.daysName[configRate.day_number]}}</label>


                                        <div v-for="(room, idx_) in configRate.dataRooms">
                                            <span class="brl text-normal">{{room.room_name}}</span>
                                            <div class="pl-3" v-show="filter_page.upd_rates" v-for="rateplan in room.rateplans">
                                                <label class="mb-0 mt-1">{{rateplan.rateplan_name}}</label>
                                                <p class="brl text-normal p-contain" v-if="rateplan.meal_plan<3">
                                                    <span class="s-left">Tarifa ({{rateplan.currency}})</span> <span class="s-right">$  {{formatMoney(rateplan.ratesnInfo.base_rate)}}</span>
                                                </p>
                                                <p class="brl text-normal p-contain" v-if="rateplan.meal_plan<3">
                                                    <span class="s-left">Extra pax ({{rateplan.currency}})</span> <span class="s-right">$  {{gapOnly(rateplan.ratesnInfo.base_rate, rateplan.ratesnInfo.extra_pax.value, (rateplan.ratesnInfo.extra_pax.link)?rateplan.ratesnInfo.extra_pax.link:'=')}}</span>
                                                </p>



                                                <p class="brl text-normal p-contain"  v-for="(assing, idx) in rateplan.ratesnInfo.assignments" v-if="rateplan.meal_plan===3 && assing.cant!==2">
                                                    <span class="s-left">Habitación {{info_page.info_links.title.extendible[assing.cant]}} ({{rateplan.currency}})</span>
                                                    <span class="s-right">$  {{gapOnly(rateplan.ratesnInfo.base_rate, assing.value, (assing.link)?assing.link:'=')}}</span>
                                                </p>

                                                <p class="brl text-normal p-contain"  v-else-if="rateplan.meal_plan===3 && assing.cant===2">
                                                    <span class="s-left">Habitación {{info_page.info_links.title.extendible[assing.cant]}} ({{rateplan.currency}})</span>
                                                    <span class="s-right">$  {{formatMoney(rateplan.ratesnInfo.base_rate)}}</span>
                                                </p>

                                                <p class="brl text-normal p-contain"  v-if="rateplan.meal_plan===3 && rateplan.ratesnInfo.jr && room.max_jr>0">
                                                    <span class="s-left">Juniors ({{rateplan.currency}})</span>
                                                    <span class="s-right">$  {{gapOnly(rateplan.ratesnInfo.base_rate, rateplan.ratesnInfo.jr.value, (rateplan.ratesnInfo.jr.link)?rateplan.ratesnInfo.jr.link:'=')}}</span>
                                                </p>

                                                <p class="brl text-normal p-contain"  v-if="rateplan.meal_plan===3 && rateplan.ratesnInfo.children && room.max_children>0">
                                                    <span class="s-left">Niños  ({{rateplan.currency}})</span>
                                                    <span class="s-right">$  {{gapOnly(rateplan.ratesnInfo.base_rate, rateplan.ratesnInfo.children.value, (rateplan.ratesnInfo.children.link)?rateplan.ratesnInfo.children.link:'=')}} </span>
                                                </p>

                                                <!--
                                                <p class="brl text-normal p-contain" v-if="rateplan.meal_plan===3">
                                                    <span class="s-left">Habitación doble ({{rateplan.currency}})</span>
                                                    <span class="s-right">$  {{formatMoney(rateplan.ratesnInfo.base_rate)}}</span>
                                                </p>
                                                <p class="brl text-normal p-contain" v-if="rateplan.meal_plan===3">
                                                    <span class="s-left">Habitación triple ({{rateplan.currency}})</span>
                                                    <span class="s-right">$  {{gapOnly(rateplan.ratesnInfo.base_rate, rateplan.ratesnInfo.triple.value, rateplan.ratesnInfo.triple.link)}}</span>
                                                </p>
                                                <p class="brl text-normal p-contain" v-if="rateplan.meal_plan===3">
                                                    <span class="s-left">Habitación cuadruple ({{rateplan.currency}})</span>
                                                    <span class="s-right">$  {{gapOnly(rateplan.ratesnInfo.base_rate, rateplan.ratesnInfo.cuadruple.value, rateplan.ratesnInfo.cuadruple.link)}}</span>
                                                </p>
                                                <p class="brl text-normal p-contain" v-if="rateplan.meal_plan===3 &&  room.max_jr>0">
                                                    <span class="s-left">Habitación Juniors ({{rateplan.currency}})</span>
                                                    <span class="s-right">$  {{gapOnly(rateplan.ratesnInfo.base_rate, rateplan.ratesnInfo.jr.value, rateplan.ratesnInfo.jr.link)}}</span>
                                                </p>
                                                <p class="brl text-normal p-contain" v-if="rateplan.meal_plan===3  &&  room.max_children>0">
                                                    <span class="s-left">Habitación niños ({{rateplan.currency}})</span>
                                                    <span class="s-right">$  {{gapOnly(rateplan.ratesnInfo.base_rate, rateplan.ratesnInfo.children.value, rateplan.ratesnInfo.children.link)}}</span>
                                                </p>-->

                                                <!--<p class="brl text-normal p-contain"><span class="s-left">Mínimo de noches</span> <span class="s-right">2</span></p>
                                                <p class="brl text-normal p-contain"><span class="s-left">Máximo de noches</span> <span class="s-right">5</span></p>
                                                <p class="brl text-normal p-contain"><span class="s-left">Cerrado a la llegada</span> <span class="s-right">Activado</span></p>
                                                <p class="brl text-normal p-contain"><span class="s-left">Cerrado a la salida</span> <span class="s-right">Activado</span></p>
                                                <p class="brl text-normal p-contain mb-3"><span class="s-left">Disponibildiad</span> <span class="s-right">Abierta</span></p>-->
                                            </div>
                                        </div>




                                    </div>
                                    <a class="a-right r-20 type-button"> <i class="fa fa-check"></i></a>
                                    <a class="a-right type-text"  @click="info_page.index_page=3"> Editar</a>
                                </div>
                                <div class="form-send" v-show="showBox([4])">
                                    <span class="fa fa-exclamation-circle s-icon"></span>
                                    <label>Verifica las actualizaciones, ya que son finales y no puedes regresar a un punto anterior.</label>


                                    <div class="line-complete">
                                        <span v-show="rateplansLinks.status" class="fa fa-exclamation-circle s-icon"></span>
                                        <label v-show="rateplansLinks.status">Recuerda estás editando un contrato con habitaciones vinculadas,
                                            la nueva tarifa se aplicará en la habitación base seleccionada y afectará a las demás.</label>
                                    </div>

                                    <div class="box-button mb-4">
                                        <button type="button" class="add-item btn-success" @click="sendInfo()">Enviar actualizaciones</button>
                                        <button type="button" class="add-item second btn-danger"  @click="cancelAction()">Cancelar</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </card>
            </div>
        </div>
    </div>

</template>
<script>

    import swal from 'sweetalert2'
    import {Breadcrumb, BreadcrumbItem, Radio} from 'src/components/index'
    import {Select, Option, DatePicker} from 'element-ui'
    //import users from 'src/pages/Dashboard/Tables/users'
    import moment from 'moment';
    //import {TaskQueue} from 'cwait';

    import PropertyService from '../../../js/services/PropertyService';
    import RoomService from '../../../js/services/RoomService';
    import RateService from '../../../js/services/RateService';
    import ContractService from '../../../js/services/ContractService';


    import EventBus from "../../../js/helpers/EventBus";
    import {vueTopprogress} from 'vue-top-progress'

    const propertyService = new PropertyService();
    const rateService = new RateService();
    const contractService = new ContractService();
    const roomService = new RoomService();
    import {mapGetters} from "vuex";
    import Loading from 'src/pages/Dashboard/Layout/LoadingMainPanel.vue'

    export default {
        components: {
            [DatePicker.name]: DatePicker,
            Breadcrumb,
            BreadcrumbItem,
            vueTopprogress,
            [Option.name]: Option,
            [Select.name]: Select,
            Radio
        },
        computed: {
            ...mapGetters(["selected_property"]),

        },
        data() {
            return {
                screenLoading: false,
                selectDefault: '--',
                screenText: '',
                vars:{
                    headers: [],
                    headers_extras: []
                },
                breadcrumbs: [
                    {
                        name: this.$t('property.index.home'),
                        path: '/admin/properties',
                        type: '',
                    },
                    {
                        name: this.$t('rate.section.room_rates'),
                        path: '/admin/rates/rates-availability',
                        type: '',
                    },

                    {
                        name: this.$t('rate.section.rates_restrict'),
                        path: '',
                        type: 'active',
                    }
                ],
                info_page: {
                    property_id: {},
                    info_links:{
                        title:{
                            normal: ['Tarifa Base', 'Extra Pax'],
                            extendible: ['', 'Sencilla', 'Doble', 'Triple', 'Cuadruple', 'Quintuple', 'Sextuple', 'Septima', 'Octava', 'Novena', 'Decima']
                        }
                    },
                    index_page: 1,
                    tab_lang: 'es',
                    daysName: ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'],
                    langs: [{
                        name: this.$t('locale_es'),
                        locale: 'es'
                    }, {
                        name: this.$t('locale_en'),
                        locale: 'en'
                    },],
                    btn_back_status: false,

                    all_types: false,
                    links_rateplans: 'Contratos vinculados<br> Cargar de manera individual',
                    contract_types: [
                        {
                            _id: 1,
                            name: 'Contratos solo habitación (EP)',
                            status: false,
                            filter: false,
                            showLinks: false,
                            activeLinks: false
                        },
                        {
                            _id: 2,
                            name: 'Contratos habitación + desayuno (EP + Desayuno)',
                            status: false,
                            filter: false,
                            showLinks: false
                        },
                        {
                            _id: 3,
                            name: 'Contrato todo incluido (AI)',
                            status: false,
                            filter: false,
                            showLinks: false
                        }
                    ],
                    states_arrive: [
                        {
                            name: 'Sin cambios',
                            value: 0
                        },
                        {
                            name: 'Abierta',
                            value: 1
                        },
                        {
                            name: 'Cerrada',
                            value: 2
                        }
                    ],
                    rooms: [],

                    types_bar: [],
                    format_payment: [
                        {
                            _id: '+$',
                            name: '( + ) $',
                            value: '1',
                            type: '$'

                        },
                        {
                            _id: '-$',
                            name: '( - ) $',
                            value: '-1',
                            type: '$'

                        },
                        {
                            _id: '+%',
                            name: '( + ) %',
                            value: '1',
                            type: '%'
                        },
                        {
                            _id: '-%',
                            name: '( - ) %',
                            value: '-1',
                            type: '%'
                        },
                        {
                            _id: '=',
                            name: '=',
                            value: '0',
                            type: '='
                        }
                    ],
                    room_model: {
                        name: 'title',
                        value: '',
                        status: 0,
                    },
                    filter_options: [],
                    buttons: {
                        page_1: false,
                        page_2: false,
                        page_3: false,
                    }
                },
                filter_page: {
                    upd_rates: true,
                    upd_restricts: false,
                    always_days: '1',
                    links_rates: '2',
                },


                calendar_info: {
                    yearHead: '',
                    monthsHead: [],
                    selected: [],
                    min: '',
                    max: '',
                    availables_days: ['-1']
                },
                inventory: {
                    date_range: ['', ''],
                    week: [1, 1, 1, 1, 1, 1, 1],
                    rooms: []

                },
                auxObject: {},
                dataRateconfigsDB: [],
                dataRateconfigs: [],
                rateplansLinks:{
                    values: [],
                    status: false
                },
                modelValue: {
                    contract: '',
                    day: ['-1'],
                    base_rate: [''],
                    extra_pax: [''],
                    extra_link: [''],
                },
                configsRates: [],
                show_errors: false,
                dataSend: [],
                pickerOptions1: {
                    disabledDate: time => {
                        var d = new Date();
                        d.setDate(d.getDate() - 1);
                        //console.log('day', d)
                        return time <= d.getTime()
                    },
                },

            }
        },
        beforeCreate(){
            //location.reload();
        },
        created() {
            this.chargerItems(this.selected_property._id);
            moment.locale('es');
            //location.reload();

            //this.$refs.topProgress.start();
        },
        methods: {
            changeProperty() {
                EventBus.$on('CHANGE_PROPERTY', (property_id) => {
                    if (this.$route.name === 'FormRestricts') {
                        this.chargerItems(property_id);
                        this.info_page.index_page = 1;
                    }

                })
            },
            async chargerItems(property_id = null) {
                this.initLoading();
                this.info_page.property_id = property_id;
                let date_ = moment().locale('es');
                let date_end = moment().add(13, 'days').format('YYYY-MM-DD');
                let date_start = moment().format('YYYY-MM-DD');
                let relRoomRateplan = [];
                this.inventory.rooms = [];
                this.info_page.buttons.page_2 = false;
                this.info_page.buttons.page_1 = false;
                this.calendar_info.selected = [];
                let property = await propertyService.getPropertyId(property_id);
                property = property.data.data.property;
                let paymnt = {};

                let typesActives = [];
                let linksActives = [];

                paymnt = this.info_page.contract_types.find(payment => payment._id === 1);
                paymnt.status = property.plan_ep;
                paymnt = this.info_page.contract_types.find(payment => payment._id === 2);
                paymnt.status = property.plan_epb;
                paymnt = this.info_page.contract_types.find(payment => payment._id === 3);
                paymnt.status = property.plan_ai;

                let rates = await rateService.getRatesTarYRest({
                    property: property_id,
                    from: date_start,
                    to: date_end,
                    rateplan_status: 1

                })
                let rateplansLinks = [];
                rates = rates.data.data.rooms;
                rates.forEach(async rateInfo => {

                    let roomModel = {
                        _id: rateInfo._id.room_id,
                        name: rateInfo._id.name,
                        max_children:  rateInfo._id.max_children,
                        max_jr:  rateInfo._id.max_jr,
                        max_pax:  rateInfo._id.max_pax,
                        rateplans: [],
                        isBaseLink: [],
                        isShow: false
                    }
                    let insert_rateplans = false;
                    rateInfo.rates_plan.forEach(rateplan => {
                        let rateList = []

                        if (rateplan.rateplanlink_data.length === 0 && rateplan.rateplan_data.status === true) {

                            let rateplanModel = {
                                _id: rateplan.rateplan_data._id,
                                name: rateplan.rateplan_data.name,
                                meal_plan: rateplan.rateplan_data.meal_plan,
                                meal_plan_filter: rateplan.rateplan_data.meal_plan,
                                rates_list: rateList,
                                cancellation_policy: rateplan.rateplan_data.cancellation_policy,
                                currency: rateplan.rateplan_data.currency,
                                rateconfig_id: rateplan.rateconfig_id,
                                default_value: this.calculateRateTotal(rateplan.base_rate),
                                isShow: false,
                                isModified: false,
                                isLinked: [],
                                linkedTo: null
                            }

                            if(rateplan.room_link && rateplan.room_link!==null){
                                rateplanModel.meal_plan_filter = rateplanModel.meal_plan+'-vinc';
                                if(linksActives.indexOf(rateplan.rateplan_data.meal_plan)<0){
                                    linksActives.push(rateplan.rateplan_data.meal_plan)
                                }

                                rateplanModel.linkedTo = rateplan.room_link;
                                let idxRelacion = relRoomRateplan.findIndex(elem => elem.room_id === rateplan.room_link &&  elem.rateplan_id === rateplan.rateplan_data._id)
                                let modelRel = {
                                    room_id: rateplan.room_link,
                                    room_name: roomModel.name,
                                    rateplan_id : rateplan.rateplan_data._id,
                                    rateplan_name : rateplan.rateplan_data.name,
                                }
                                if(idxRelacion<0){
                                    relRoomRateplan.push(modelRel)
                                }

                                /*let idxFinded = this.inventory.rooms.findIndex(item => item._id === rateplan.room_link)
                                //let idxFinded = relRoomRateplan.findIndex(item => item.room_id === rateplan.room_link)
                                if(idxFinded>=0){
                                    if( this.inventory.rooms[idxFinded].isBaseLink.length===0){
                                        let roomObject =  this.inventory.rooms[idxFinded];
                                        let idxRateplan = roomObject.rateplans.findIndex(item => item._id === rateplan.rateplan_data._id)
                                        if(idxRateplan>=0){
                                            this.inventory.rooms[idxFinded].isBaseLink.push(rateplan.rateplan_data.name);
                                            this.inventory.rooms[idxFinded].rateplans[idxRateplan].isLinked.push(roomObject.name);
                                            //console.log('room_link', this.inventory.rooms[idxFinded]);
                                        }
                                    }
                                }*/

                            }
                            rateplan.rateplan_data.rates_list.forEach((element, idxBar)=>{
                                rateList.push({
                                    'name': this.assigNameRack(idxBar),
                                    'value': element,
                                    'editable': false
                                })
                            })

                            if(rateplanModel.meal_plan === 3){
                                rateplanModel.infoRates = {
                                    assignments: rateplan.assignments,
                                    jrs_price: rateplan.rateplan_data.jrs_price,
                                    children_price: rateplan.rateplan_data.children_price,
                                    jr_link_type: rateplan.rateplan_data.jr_link_type,
                                    children_link_type: rateplan.rateplan_data.children_link_type,
                                }

                            }else{
                                rateplanModel.infoRates = {
                                    extra_pax: rateplan.rateplan_data.extra_pax,
                                    extra_pax_link: rateplan.rateplan_data.extra_pax_link,
                                }
                            }
                            roomModel.rateplans.push(rateplanModel);
                            insert_rateplans = true;
                            if(typesActives.indexOf(rateplan.rateplan_data.meal_plan)<0){
                                typesActives.push(rateplan.rateplan_data.meal_plan)
                            }

                        }
                        if (rateplan.rateplanlink_data.length > 0 && rateplan.rateplan_data.status === true) {
                            rateplan.rateplanlink_data.forEach(rateplanLink=>{
                                if(rateplansLinks.indexOf(rateplanLink._id)<0){
                                    rateplansLinks.push(rateplanLink._id);
                                }
                            })
                        }
                        rateList.push({
                            'name': 'Manual',
                            'value': '--',
                            'editable': true
                        });
                    });
                    if(insert_rateplans){
                        this.inventory.rooms.push(roomModel);
                    }
                })
                relRoomRateplan.forEach(elem=>{
                    let idxFinded = this.inventory.rooms.findIndex(item => item._id === elem.room_id)
                    if(idxFinded>=0){
                        let roomObject =  this.inventory.rooms[idxFinded];
                        let idxRateplan = roomObject.rateplans.findIndex(item => item._id === elem.rateplan_id)
                        this.inventory.rooms[idxFinded].rateplans[idxRateplan].isLinked.push(elem.room_name);
                        this.inventory.rooms[idxFinded].isBaseLink.push(elem.rateplan_name);
                    }
                })
                this.info_page.contract_types.forEach(cType=>{
                    if(typesActives.indexOf(cType._id)>=0 && cType.status === true){
                        cType.status = true;
                    }else{
                        cType.status = false;
                    }
                    if(linksActives.indexOf(cType._id)>=0){
                        cType.showLinks = true;
                    }else{
                        cType.showLinks = false;
                    }
                })

                this.closeLoading();
                this.rateplansLinks.values = rateplansLinks
                this.calendarDashboard(date_);
            },

            calculateSize(room, ratesnInfo){
                 let minSize = ratesnInfo.assignments.length;
                 if(room.max_pax<minSize){
                     minSize = room.max_pax;
                 }

                 if(ratesnInfo.jr && room.max_jr>0){
                     minSize++;
                 }
                if(ratesnInfo.children && room.max_children>0){
                    minSize++;
                }

                return (minSize>6)?'big-size':''
            },

            changeBar(event, condition){
                //console.log('et.v', event, condition);
                if(event === '--'){
                    condition.editable = true;
                }else{
                    condition.editable = false;
                    //condition.value = event
                }

            },



            calculateRateTotal(value, type){
                let valueReturn = 0;

                valueReturn = value;


                return valueReturn;
            },

            /************           Calendario funciones                    ************/

            addRangeCalendar() {
                let array_dates = this.inventory.date_range;

                let date_selecteds = this.calendar_info.selected;
                let range_init = array_dates[0];

                let range_start = moment(array_dates[0]);
                let range_end = moment(array_dates[1]);

                let weeekdays = this.inventory.week;

                while (range_start <= range_end) {

                    let status_day = weeekdays[range_start.day()];
                    if (status_day) {
                        let idx = date_selecteds.indexOf(range_start.format('YYYY-MM-DD'));
                        if (idx < 0) {
                            this.calendar_info.selected.push(range_start.format('YYYY-MM-DD'))
                        }
                    }
                    range_start.add(1, 'days');
                }
                this.checkButtonAvailable('page_1');
                let date_end_now = moment(this.calendar_info.monthsHead[2], 'MM-YYYY').startOf('month');
                let date_start_new = moment(range_init)
                //console.log(date_start_new>date_end_now, date_start_new, date_end_now, 'stop');
                if(date_start_new>date_end_now){
                    this.calendarDashboard(date_start_new)
                    this.checkDateNow()
                }
            },
            addMonth(month_s) {
                let start_of = moment(month_s, 'MM-YYYY').startOf('month')
                let daysMonth = moment(month_s, 'MM-YYYY').endOf('month');
                let date_selecteds = this.calendar_info.selected;
                let array_result = [];

                while (start_of < daysMonth) {


                    let aux = start_of.format('YYYY-MM-DD')
                    let date_now = moment();
                    if (!moment(date_now.format('YYYY-MM-DD')).isAfter(aux, 'day')) {

                        //console.log('es un dia disponible', aux)
                        let idx = date_selecteds.indexOf(start_of.format('YYYY-MM-DD'));
                        if (idx < 0) {
                            array_result.push(start_of.format('YYYY-MM-DD'))
                        }
                    }

                    start_of.add(1, 'days')
                }
                //console.log('arr_r', array_result.length)

                if (array_result.length === 0) {
                    this.deleteMonth(month_s)
                } else {
                    this.calendar_info.selected = date_selecteds.concat(array_result)
                }

                this.checkButtonAvailable('page_1');

            },
            deleteMonth(month_s) {
                let start_of = moment(month_s, 'MM-YYYY').startOf('month')
                let daysMonth = moment(month_s, 'MM-YYYY').endOf('month');
                let date_selecteds = this.calendar_info.selected;

                while (start_of < daysMonth) {

                    let idx = date_selecteds.indexOf(start_of.format('YYYY-MM-DD'));
                    if (idx >= 0) {
                        date_selecteds.splice(idx, 1)
                    }
                    start_of.add(1, 'days')
                }
            },
            resetCalendar() {
                this.calendar_info.selected = [];
                this.checkButtonAvailable('page_1');
                this.inventory.week = [1, 1, 1, 1, 1, 1, 1];
                this.inventory.date_range = ['', ''];
            },
            calendarDashboard(date_) {
                let originalDate = moment(date_)
                let start_month = date_.format('MM-YYYY');
                let middle_month = date_.add(1, 'M').format('MM-YYYY');
                let end_month = date_.add(1, 'M').format('MM-YYYY');
                //this.calendar_info.monthsHead = [start_month];

                this.calendar_info.monthsHead = [start_month, middle_month, end_month];
                this.calendar_info.yearHead = originalDate.add(1, 'M').format('YYYY');

            },
            dayCalendar(date) {
                let aux = '';
                if (date !== null) {
                    aux = moment(date, 'YYYY-MM-DD').format('D')
                }

                return aux
            },
            addCalendar(date) {
                let dates_Array = this.calendar_info.selected;
                if (date !== null) {


                    let aux = date
                    let date_now = moment();
                    if (!moment(date_now.format('YYYY-MM-DD')).isAfter(aux, 'day')) {

                        //console.log('es un dia disponible')
                        let idx = dates_Array.indexOf(date);
                        if (idx >= 0) {
                            dates_Array.splice(idx, 1)

                        } else {
                            dates_Array.push(date);
                        }
                    }
                }
                this.checkButtonAvailable('page_1');

                //console.log('dates_Array', dates_Array)
            },
            nameMonth(date) {
                return moment(date, 'MM-YYYY').format('MMMM')
            },
            checkDateNow() {

                //let date = moment(this.calendar_info.monthsHead[0], 'MM-YYYY').subtract(1, 'M').format('YYYY-MM-DD')
                let date = moment(this.calendar_info.monthsHead[0], 'MM-YYYY')
                let date_now = moment();
                //console.log(date, moment().isAfter(date, 'month'), 'test')
                this.info_page.btn_back_status = date.isAfter(date_now, 'month')
            },
            updDate(action) {
                let date = moment(this.calendar_info.monthsHead[0], 'MM-YYYY')
                if (action === 'plus') {
                    date.add(1, 'M')
                    this.calendarDashboard(date)

                } else if (action === 'minus') {
                    date.subtract(1, 'M')
                    this.calendarDashboard(date)
                }
                this.checkDateNow();

            },
            mealPlanVisible(rateplan){
                let mealPlan = rateplan.meal_plan;
                //console.log('mealPlan', mealPlan);
                let contract_types = this.info_page.contract_types;
                let statusRateplan = contract_types.find(elemItem => elemItem._id === mealPlan)
                if(statusRateplan){
                    rateplan.isShow = statusRateplan.filter;
                    //console.log('statusRateplan', statusRateplan);

                }
                let statusRateplanLinks = contract_types.find(elemItem => elemItem._id+'-vinc' === mealPlan)
                if(statusRateplanLinks){
                    //console.log('statusRateplanLinks', statusRateplanLinks);
                    rateplan.isShow  = statusRateplanLinks.activeLinks;
                }

            },
            checkDay(date) {
                let dates_Array = this.calendar_info.selected;
                let response = '';
                let resp_status = '';

                if (date !== null) {
                    response = dates_Array.indexOf(date);
                    if (response >= 0) {
                        resp_status = 'select-day';
                    }

                    let aux = date
                    let date_now = moment();
                    if (moment(date_now.format('YYYY-MM-DD')).isAfter(aux, 'day')) {


                        resp_status = 'block-day';
                        //console.log('bloquear date', moment(aux).isSameOrAfter(date_now.format('YYYY-MM-DD'), 'day'))

                    }
                }
                return resp_status;
            },
            checkClass(idx) {
                let result_class = '';
                if (idx === 0) {
                    result_class += 'w-80';
                }
                else if (idx === 2) {
                    result_class += 'ml-20 w-80';
                }
                return result_class
            },
            checkMonth(month_s) {
                let start_of = moment(month_s, 'MM-YYYY').startOf('month')
                let daysMonth = moment(month_s, 'MM-YYYY').endOf('month');
                let array_result = [];
                let array_model = [null, null, null, null, null, null, null];
                //console.log('month_s', start_of.week)

                while (start_of < daysMonth) {

                    array_model[start_of.day()] = start_of.format('YYYY-MM-DD');
                    if (start_of.day() === 6) {

                        array_result.push(array_model);
                        array_model = [null, null, null, null, null, null, null];
                    }

                    start_of.add(1, 'days')
                }

                array_result.push(array_model);
                //console.log('array_result', array_result)
                return array_result;

            },
            orderDate() {
                if (this.info_page.buttons.page_1) {
                    let calendar_array = this.calendar_info.selected;
                    //console.log('orderDate', )
                    calendar_array = calendar_array.sort()

                    this.calendar_info.min = moment(calendar_array[0]).format('DD MMM YYYY');
                    this.calendar_info.max = moment(calendar_array[calendar_array.length - 1]).format('DD MMM YYYY');

                    this.info_page.index_page = 2;
                    this.checkDaysAvailables()
                }
            },
            checkDaysAvailables() {
                //console.log('checkDaysAvailables')
                let dates_Array = this.calendar_info.selected;
                let array_aux = [];

                dates_Array.forEach(item => {

                    let result = array_aux.indexOf(moment(item).day());
                    if (result < 0) {
                        array_aux.push(moment(item).day())
                    }
                })

                this.calendar_info.availables_days = array_aux.sort()

                let avai_days = this.calendar_info.availables_days;
                let ky_sunday = avai_days.indexOf(0)
                if (ky_sunday >= 0) {
                    avai_days.splice(ky_sunday, 1);
                    avai_days.push(0);
                }
                //console.log('this.calendar_info.availables_days', this.calendar_info.availables_days)

            },
            changeRateChoose(rate_choose,  configIdx, idx_, cnt_idx){
                this.dataRateconfigs[configIdx].dataRooms[idx_].rateplans[cnt_idx].ratesnInfo.base_rate = rate_choose;
                this.checkChanges();

            },
            checkChanges() {
                let configsRates = this.dataRateconfigs;
                let passValidate = true;

                configsRates.forEach(days_key => {
                    days_key.dataRooms.forEach(room => {
                        room.rateplans.forEach(rateplan => {
                            let room_number = 4;
                            let arrayValidate = [rateplan.ratesnInfo.base_rate];
                            if(this.validadorCustom(arrayValidate, 'number')){
                                passValidate = false;
                            }

                            //console.log('rateplan', rateplan, arrayValidate);
                        });
                    });
                });

                //console.log('passValidate', passValidate);
                this.info_page.buttons.page_3 = passValidate;

            },
            checkButtonAvailable(type_) {
                switch (type_) {

                    case 'page_1':
                        if (this.calendar_info.selected.length) {
                            this.info_page.buttons.page_1 = true;
                        } else {
                            this.info_page.buttons.page_1 = false;
                        }
                        break;
                    case 'page_2':
                        /*if(this.contracts.modified.length) {
                            this.info_page.buttons.page_2 = true;
                        }else{
                            this.info_page.buttons.page_2 = false;
                        }*/

                        break;
                    case 'page_3':

                        break;

                }
            },
            changeTypeFilter(type_, value_ = null, status_is = false) {
                let all_t = this.info_page.all_types;
                let rooms = this.inventory.rooms
                //let filter_options = this.info_page.filter_options
                let contract_types = this.info_page.contract_types;

                if (type_ === 'all') {
                    if (all_t) {
                        contract_types.forEach((item, idx) => {
                            if (item.status && all_t) {
                                this.$set(this.info_page.contract_types[idx], 'filter', true)
                                this.changeTypeFilter('item', this.info_page.contract_types[idx]._id, true)
                            }
                        })
                    }
                }

                rooms.forEach((item, idx) => {
                    let activeRoom =  false;
                    item.rateplans.forEach((rateplan, key) => {
                        //console.log('changeFilter', rateplan);
                        let statusRateplan = contract_types.find(elemItem => elemItem._id === rateplan.meal_plan_filter )
                        if(statusRateplan && rateplan.meal_plan_filter === value_){
                            rateplan.isModified = statusRateplan.filter;
                            rateplan.isShow = statusRateplan.filter;
                            if(!statusRateplan.filter){
                                statusRateplan.activeLinks = false;
                                this.changeTypeFilter('item', value_+'-vinc', statusRateplan.filter)
                            }
                        }
                        let statusRateplanLinks = contract_types.find(elemItem => elemItem._id+'-vinc' === rateplan.meal_plan_filter)
                        if(statusRateplanLinks && rateplan.meal_plan_filter === value_){
                            //console.log(statusRateplanLinks);
                            rateplan.isModified = statusRateplanLinks.activeLinks;
                            rateplan.isShow = statusRateplanLinks.activeLinks;
                        }
                        if(rateplan.isShow){
                            activeRoom = true;
                        }
                    })

                    item.isShow = activeRoom;

                })
                this.checkContractModified();
            },
            checkContractModified() {
                let rooms = this.inventory.rooms;
                let numberModified = 0
                rooms.forEach(room => {
                    room.rateplans.forEach(rateplan => {
                        if (rateplan.isModified) {
                            numberModified++;
                        }
                    });
                })
                if(numberModified) {
                    this.info_page.buttons.page_2 = true;
                } else {
                    this.info_page.buttons.page_2 = false;
                }
            },

            validadorCustom(fields, type){
                let booleanStatus = false;
                fields.forEach(field=>{
                    if(type === 'string'){
                        field = field+'';
                        if(field==='null' ||  field==='' || field.trim().length === 0 ){
                            booleanStatus = true ;
                        }
                    }
                    if(type==='number'){
                        if(isNaN(parseFloat(field)) || !isFinite(field)){
                            booleanStatus = true ;
                        }
                    }
                });

                return booleanStatus;

            },

            async changeDays(){
                //console.log('changeDays', this.calendar_info.availables_days);
                if(this.filter_page.always_days==='1'){
                    await this.configureRateConfigs(['-1']);
                }else{
                    await this.configureRateConfigs(this.calendar_info.availables_days);
                }

            },

            async configureRateConfigs(days_selected) {
                this.dataRateconfigs = [];

                this.rateplansLinks.status = false;
                this.info_page.buttons.page_3 = false
                //console.log('configureRateConfigs', rooms)
                //let days_selected = arrayElements;

                let resultDays = await this.recursiveDaysSelected(days_selected, [])

                //console.log('general days 1:', resultDays);

                this.dataRateconfigs = resultDays;

                this.info_page.index_page = 3
            },

            async recursiveDaysSelected(days_selected, resultsDay = [] ){
                let day_selected = days_selected[0];
                let rooms =    JSON.parse(JSON.stringify(this.inventory.rooms));
                let model_day = {
                    day_number: day_selected,
                    dataRooms:[]
                }

                //console.log('setp days 1:', rooms);

                let responseRooms = await this.recursiveRoomSelected(rooms, [])
                //console.log('setp days 1:', responseRooms);


                model_day.dataRooms = responseRooms;

                resultsDay.push(model_day);

                days_selected.splice(0, 1)
                if(days_selected.length){
                    await this.recursiveDaysSelected(days_selected, resultsDay)
                }

                return resultsDay;

            },

            async recursiveRoomSelected(rooms, resultsRooms = []){

                let room = {...rooms[0]};
                //console.log('rooms', room)
                let modelConfig = {
                    room_id: room._id,
                    room_name: room.name,
                    rateplans: [],
                    max_pax: room.max_pax,
                    max_children:  room.max_children,
                    max_jr:  room.max_jr

                }

                let rateplans = [...room.rateplans];
                /*rateplans.forEach(rateplan=>{
                    if(isNaN(rateplan.meal_plan)){
                        rateplan.meal_plan = parseInt(rateplan.meal_plan.slice(0, 1))
                    }
                })*/

                let responseRateplan = await this.recursiveRateplanSelected(rateplans, [], room)
                //console.log('step rooms :',  responseRateplan);

                if(responseRateplan.length){
                    modelConfig.rateplans = responseRateplan;
                    resultsRooms.push(modelConfig)
                }

                //model_day.dataRooms.push(modelConfig)
                rooms.splice(0, 1)
                if(rooms.length){
                    await this.recursiveRoomSelected(rooms, resultsRooms)
                }
                return resultsRooms;
            },
            async recursiveRateplanSelected(rateplans, resultsRateplans, roomInfo){
                let rateplan = {...rateplans[0]};

                let modelRateplan = null;





                //console.log('step rateplans I:', rateplan);
                if (rateplan.isModified) {


                    let rateList = [];
                    let editableIs = true;
                    rateList = [...rateplan.rates_list];
                    editableIs = true;


                    if(rateplan.isLinked.length || rateplan.linkedTo !== null){
                        rateList = [...rateplan.rates_list];
                        editableIs = true;
                    }else{
                        rateList.push({
                            'name': 'Manual',
                            'value': '--',
                            'editable': true
                        })
                        editableIs = false;

                    }
                    modelRateplan = {
                        rateconfig_id: rateplan.rateconfig_id,
                        rateplan_id: rateplan._id,
                        rateplan_name: rateplan.name,
                        meal_plan: rateplan.meal_plan,
                        currency: rateplan.currency,
                        rates_list: [...rateList],
                        hasRoomLink: rateplan.hasRoomLink,
                        editable : true,
                        select_editable: editableIs,
                        reset_config: rateplan.reset_config,
                        ratesnInfo: await this.updateRatesConfigs(rateplan.rateconfig_id, (rateplan.meal_plan < 3), rateplan.isLinked.length>0)
                    }
                    modelRateplan.hasRoomLink = modelRateplan.ratesnInfo.hasRoomLink;
                    //console.log('dev', modelRateplan);
                    if(rateplan.reset_config){
                        this.rateplansLinks.status = true;
                    }


                    if(modelRateplan.ratesnInfo.base_link){
                        //console.log('rateList:', rateplan.rateconfig_id+': '+ modelRateplan.ratesnInfo.base_link.value, rateList);
                        modelRateplan.rates_list.forEach(item=>{
                            if(item.value!=='--'){
                                item.value = this.gapOnly(item.value, modelRateplan.ratesnInfo.base_link.value, modelRateplan.ratesnInfo.base_link.link, false)
                            }
                        })
                    }
                    if(rateplan.isLinked.length){
                        modelRateplan.rates_list.forEach(item=>{
                            if(item.value!=='--'){
                                item.value = this.formatMoney(item.value, false)
                            }
                        })
                    }


                    /*if(rateConfigItem.room_link_type && rateConfigItem.room_link_type==="0"){
                        rateplan.hasRoomLink = true;
                        this.rateplansLinks.status = true;
                    }else{
                        rateplan.hasRoomLink = false;
                    }*/


                }

                if(modelRateplan!==null){
                    resultsRateplans.push(modelRateplan);
                    //console.log('modelConfig', modelConfig)
                }


                rateplans.splice(0, 1)


                //console.log('step rateplans I:', rateplans);
                //console.log('rateplans', rateplans.length)
                if(rateplans.length) {
                    await this.recursiveRateplanSelected(rateplans, resultsRateplans, roomInfo);
                }

                //console.log('resultsRateplans', resultsRateplans)
                return resultsRateplans;
            },

            costVinculate(room_id, idIs){
                //console.log('costVinculate', room_id, idIs);
                /*let costIs = 0;
                if(this.rateplan.rateplan_link.rateplan!==''){
                    //console.log('test', this.vars.costRateconfigs);
                    let infoRoom = this.vars.costRateconfigs.find(room => room.room_id === room_id);
                    if(infoRoom){
                        let infoPrice = infoRoom.costs.find(cost => cost.id === idIs);
                        costIs = rateplanFuntions.gapFunction(infoPrice.value, this.rateplan.rateplan_link.value, this.rateplan.rateplan_link.type)
                        //console.log('infoPrice', infoPrice);
                    }


                    return '$ '+ rateplanFuntions.formatMoney(costIs);
                }*/

            },

            determineResets(){
              let rooms = this.inventory.rooms;
                rooms.forEach(room=>{
                    room.rateplans.forEach(rateplan=>{
                        let mealPlanFilter = this.info_page.contract_types.find(element => element._id === rateplan.meal_plan)
                        //console.log('rateplan', rateplan, mealPlanFilter);
                        rateplan.reset_config = false;
                        /*if(rateplan.isLinked && rateplan.isLinked.length && rateplan.isModified){
                            rateplan.reset_config = true;
                            //console.log('rateplan.reset_config', rateplan);
                        }*/

                        if(rateplan.linkedTo === null && !mealPlanFilter.activeLinks && mealPlanFilter.filter && rateplan.isLinked.length && rateplan.isModified){
                            rateplan.reset_config = true;
                        }else{
                            rateplan.reset_config = false;
                        }

                        /*if(rateplan.isModified && rateplan.linkedTo!==null){
                            let infoRoom = rooms.find(roomInfo => roomInfo._id === rateplan.linkedTo)
                            if(infoRoom){
                                let infoRateplan = infoRoom.rateplans.find(rateplanInfo => rateplanInfo._id === rateplan._id)




                                if(infoRateplan){
                                    infoRateplan.reset_config = false;
                                }
                            }
                        }*/
                    })
                })
            },


            async updateRatesConfigs(rateconfig_id, meal_plan, isLinked){
                let rateConfigs = await contractService.getRateConfigId(rateconfig_id)
                let dataRateconfigsDB = rateConfigs.data.data.rateconfig;
                let modelValue = {};
                modelValue.reset_config = false;


                //console.log(dataRateconfigsDB, isLinked);




                if(dataRateconfigsDB.room_link && dataRateconfigsDB.room_link!==null){
                    modelValue.hasRoomLink = false;

                    modelValue.base_link = {
                        link: dataRateconfigsDB.room_link_type,
                        value: dataRateconfigsDB.base_rate,
                    };

                }else{
                    if(isLinked){
                        modelValue.hasRoomLink = true;

                    }
                }


                modelValue.base_rate = '';

                if (meal_plan) {
                    let baseCost = 0;
                    //modelValue.base_rate = '';

                    modelValue.extra_pax = {
                        value: dataRateconfigsDB.extra_pax,
                        link: ''
                    }
                    if(dataRateconfigsDB.room_link_type_pax){
                        modelValue.extra_pax.link = dataRateconfigsDB.room_link_type_pax;
                    }else{
                        delete modelValue.extra_pax.link;
                    }



                } else {

                    modelValue.assignments = dataRateconfigsDB.assignments;
                    let objectSecond = {
                        cant: 2
                    }

                    modelValue.assignments.push(objectSecond)
                    modelValue.assignments.sort((a,b) => (a.cant > b.cant) ? 1 : ((b.cant > a.cant) ? -1 : 0))
                    /* modelValue.sencilla = {
                         value: dataRateconfigsDB.assignments[0].value,
                         link: ''
                     }
                     modelValue.double = {
                         value: ''
                     }
                     modelValue.triple = {
                         value: dataRateconfigsDB.assignments[1].value,
                         link: ''
                     }
                     modelValue.cuadruple = {
                         value: dataRateconfigsDB.assignments[2].value,
                         link: ''
                     }

                     modelValue.jr = {
                         value: dataRateconfigsDB.jrs_price,
                         link: ''
                     }

                     modelValue.children = {
                         value: dataRateconfigsDB.children_price,
                         link: ''
                     }

                     if(dataRateconfigsDB.assignments[0].link){
                         modelValue.sencilla.link = dataRateconfigsDB.assignments[0].link;
                     }else{
                         modelValue.sencilla.link = '=';
                     }
                     if(dataRateconfigsDB.assignments[1].link){
                         modelValue.triple.link = dataRateconfigsDB.assignments[1].link;
                     }else{
                         modelValue.triple.link = '=';
                     }
                     if(dataRateconfigsDB.assignments[2].link){
                         modelValue.cuadruple.link = dataRateconfigsDB.assignments[2].link;
                     }else{
                         modelValue.cuadruple.link = '=';
                     }*/
                    modelValue.jr = {
                        value: dataRateconfigsDB.jrs_price
                    }

                    modelValue.children = {
                        value: dataRateconfigsDB.children_price
                    }

                     if(dataRateconfigsDB.jr_link_type){
                         modelValue.jr.link = dataRateconfigsDB.jr_link_type;
                     }else{
                         modelValue.jr.link = '=';
                     }

                     if(dataRateconfigsDB.children_link_type){
                         modelValue.children.link = dataRateconfigsDB.children_link_type;
                     }else{
                         modelValue.children.link = '=';
                     }
                }




                //console.log('modelValue', modelValue);

                return modelValue;
            },

            async nextActions(statusAdvanced = false) {
                if (this.info_page.buttons.page_2) {
                    /*if(statusAdvanced){
                        await this.configureRateConfigs(['-1']);
                        this.checkDaysAvailables();
                        //this.formatInfoDay();
                        this.info_page.index_page = 3
                    }else{
                        await this.checkRateplansLinks();
                    }*/
                    this.determineResets();
                    await this.configureRateConfigs(['-1']);
                    this.checkDaysAvailables();
                }
            },



            async checkRateplansLinks(statusReturn = 0){

            },

            toActualizaciones(){
                if(this.info_page.buttons.page_3 ){
                    this.info_page.index_page=4;
                }
            },

            async  sendInfo(){
                this.initLoading('Actualizando tarifas y restricciones')

                let configsNews = this.dataRateconfigs;
                let formatRate = [];

                /*swal({
                    title: 'Actualizando tarifas y restricciones',
                    html:'<img src="/static/img/loading.svg" style="width: 100px"/>'
                    ,
                    showConfirmButton: false,
                    buttonsStyling: false
                })*/
                //console.log('configsNews', configsNews)
                configsNews.forEach(itemConfig=>{
                    let dates_array = [];
                    let day_number = itemConfig.day_number;
                    if(day_number==='-1'){
                        dates_array = this.calendar_info.selected;
                    }else{
                        let array_aux = this.calendar_info.selected;
                        array_aux.forEach(dateItem=>{
                            let itemDay = moment(dateItem, 'YYYY-MM-DD').day()
                            if(itemDay===day_number){
                                dates_array.push(dateItem)
                            }
                        })
                    }
                    dates_array.forEach(dateSelected=>{
                        itemConfig.dataRooms.forEach(room=>{
                            room.rateplans.forEach(rateplan=>{
                                let modelRateConfig ={
                                    type: 'custom',
                                    rate_config: rateplan.rateconfig_id,
                                    date: dateSelected,
                                    value: rateplan.ratesnInfo.base_rate,
                                    reset_config: rateplan.reset_config
                                };

                                formatRate.push(modelRateConfig);
                            })
                        });
                    })
                })
                //console.log('en construccion', this.calendar_info.min);

                await rateService.arrayRates(formatRate).then(response=>{
                    this.showSwal(this.$t('rate.dashboard.msg'));;
                    this.closeLoading();
                });


            },
            cancelAction(){
                this.$router.push({
                    name: 'Rates'
                });
            },

            /***************             Funciones generales ********************/
            formatMoney(value, formated=true){
                //console.log('value', value);
                if(value==='' || isNaN(parseFloat(value)) || !isFinite(value)){
                    value = 0;
                }
                value = Number(value)
                if(formated){
                    return this.numberWithCommas(value.toFixed(2))
                }else{
                    return value.toFixed(2)
                }
            },

            numberWithCommas(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },


            gapOnly(base, value, link_type, formatted= true){

                let result = 0;
                if(value!=='' && base !==''){
                    base = Number(base);
                    value = Number(value);

                    switch (link_type) {
                        case '+$':
                            result = (base + value);
                            break;

                        case '-$':
                            result =  (base - value);
                            break;

                        case '-%':
                            result =  (base - (base/100*value));

                            break;
                        case '+%':
                            result =  (base + (base/100*value));
                            break;
                        case '=':
                            result = value;
                            break;
                        default:
                            result = 0;
                            break;
                    }
                }

                return this.formatMoney(result, formatted);

            },
            showBox(array_obj) {
                let view_page = false;
                let page_now = this.info_page.index_page;

                let stats = array_obj.indexOf(page_now);
                if (stats >= 0) {
                    view_page = true;
                }
                return view_page;
            },
            testLog(test) {
                //console.log('test log', test)
            },
            assigNameRack(idxk){
                let acomp = '';
                if(idxk===0){

                    acomp = 'Rack';
                }else if(idxk===1){
                    acomp = 'Bar';
                }else{
                    acomp = 'Bar '+((idxk));
                }
                return acomp;
            },
            showSwal(msg) {

                swal({
                    title: this.$t('rate.dashboard.modal'),
                    text: msg,
                    buttonsStyling: false,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    type: 'success'
                }).then(() => {
                    this.$router.push({
                        name: 'RatesDates',
                        params: {
                            date: moment(this.calendar_info.min, 'DD MMM YYYY').format('YYYY-MM-DD'),
                        }
                    })
                    /*this.$router.push({
                        name: 'Rates'
                    });*/
                });
            },
            formatText(isBaseLink){
                let stringReturn = 'Habitación base de contrato: ';

                isBaseLink.forEach((item, idx)=>{
                    //console.log(item);
                    if(idx<1){
                        stringReturn += ' '+item;
                    }else{
                        stringReturn += ', '+item;
                    }

                })
                return stringReturn;

            },
            initLoading(text = 'Cargando...') {
                this.screenLoading = true;
                this.screenText = text;
            },
            closeLoading(){
                this.screenLoading = false;
            }

        },
        mounted() {
            this.changeProperty()
        }
    }
</script>
<style lang="scss">
    .lh-40 {
        line-height: 40px;
    }
    .mt-25{
        margin-top: 32px;

    }
    span.c-disabled {
        color: rgba(118, 118, 118, 0.3);
    }
    .box-inventory {
        border: 0;
        .is_title {
            font-size: 16px;
            margin: 0 0 15px;
        }
        .subtitle {
            font-weight: 600;
            margin-bottom: 25px;
        }

        .box-button {
            //padding-left: 10px;
            .add-item {
                width: 100%;
                height: 40px;
                color: #fff;
                background-color: #222222;
                border: 2px solid #000;
                &.inactive {
                    opacity: .2;
                    cursor: default;
                }
            }
        }
        .form-updates {
            .head {

                label {
                    font-size: 14px;
                    margin-bottom: 30px;
                    color: #666;
                    &.subtitle {
                        margin-bottom: 0;
                        font-size: 16px;
                        color: #000;
                    }
                }
            }
            .group-upd {
                margin-top: 15px;
                display: inline-block;
                label {
                    font-weight: 600;
                    margin-bottom: 0;
                }
                span {
                    font-weight: 400;
                    margin-bottom: 10px;
                    display: inline-block;
                    color: #666;
                    font-size: 14px;
                }
                .select-default {
                    display: inline-block;
                    width: calc(50% - 5px);
                    float: left;
                    margin-left: 5px;
                    .el-input {
                        input {
                            padding-left: 10px;
                            padding-right: 30px;
                        }
                    }
                }

                .input-default {
                    display: inline-block;
                    width: calc(50% - 5px);
                    margin-right: 5px;
                    float: left;
                }

            }
        }
        .form-dates {

            .box-dates {
                .form-check {
                    display: inline-block;
                    margin-right: 20px;
                }
            }
            .dates-manually {
                width: 100%;
                .box-calendar {
                    width: 100%;
                }
                .box-legends {
                    width: 100%;
                    padding: 15px 0;
                    display: inline-block;
                    label {
                        width: 50%;
                        float: left;
                        display: inline-block;
                        margin-bottom: 0;
                        &.color-blue {
                            color: #3B4FBF;
                        }
                        &.right-t {
                            text-align: right;
                        }
                        .example-picker {
                            display: inline-block;
                            height: 16px;
                            width: 16px;
                            background: #3B4FBF;
                            margin-right: 7px;
                            border-radius: 15px;
                        }
                    }

                }
            }
        }

        .w-border {
            border: 2px solid #bebebe;
            padding: 10px 25px;
            margin-bottom: 15px;
        }
        .form-preview {
            position: relative;
            &.form-resume{
                label{
                    float: left;
                }
                .p-contain {
                    float: left;
                }
                .brl{
                    float: left;
                }
            }
            .l-title {
                font-weight: 600;
            }
            .text-normal {
                font-size: 14px;
            }
            .brl {
                display: inline-block;
                width: 100%;
            }
            .a-right {
                position: absolute;
                right: 50px;
                top: 0;
                bottom: 0;
                left: auto;
                margin: auto;
            }
            .type-button {
                width: 45px;
                height: 45px;
                color: #ffffff;
                text-align: center;
                border-radius: 100px;
                cursor: pointer;
                i {

                    font-size: 25px;
                    line-height: 45px;
                    left: -3px;
                    position: relative;
                }
                &:hover {
                    color: #fff;
                }
            }
            .type-button {
                right: 120px;
            }
            .type-text {
                height: 45px;
                line-height: 45px;
                cursor: pointer;
            }
            .p-contain {
                width: calc(100% - 40%);
                position: relative;
                display: inline-block;
                border-bottom: 1px solid;
                margin-bottom: 5px;
                .s-left {
                    display: inline-block;
                    width: 50%;
                }
                .s-right {
                    text-align: right;
                    display: inline-block;
                    width: 50%;
                }
            }

        }
        .form-send {
            .s-icon {
                font-size: 20px;
                color: #bf9000;
                float: left;
            }
            label {
                display: inline-block;
                color: #bf9000;
                font-size: 14px;
                width: auto;
                margin-left: 10px;
            }
            .line-complete{
                label{
                    width: 90%;
                }
            }
            .box-button {
                button {
                    width: calc(33.3% - 30px);
                    margin-right: 15px;
                }
            }

        }

        .box-top {
            padding: 25px;
            border: 2px solid #eeeeee;
            margin-bottom: 15px;
        }
        label {
            width: 100%;
            display: inline-block;
            margin-bottom: 15px;
            font-size: 16px;
            text-transform: unset;
            span {
                font-weight: 400;
                margin-left: 5px;
            }
            span.icon-cont {
                margin-left: 0;
            }

        }
        h6 {
            margin-bottom: 15px;
            color: #085394;
            font-size: 18px;
            text-transform: unset;
        }
        .box-calendar {
            .item-month {

                .calendar-title {
                    font-size: 17px;
                    text-align: center;
                    font-weight: 600;
                    text-transform: uppercase;
                    margin-bottom: 5px;
                    //width: 60%;
                    &.w-80 {
                        width: 60%;
                    }
                    &.ml-20 {
                        margin-left: 20%;
                    }
                }
                .sp-icon {
                    width: 20%;
                    text-align: center;
                    &.n-visible {
                        visibility: hidden;
                    }
                }
                display: inline-block;
                width: 30%;
                float: left;
                &:first-child {
                    margin-right: 5%;
                }
                &:last-child {
                    margin-left: 5%;
                }
                .line-week {
                    width: 100%;
                    display: inline-block;
                    &.lw-head {
                        margin-bottom: 0;
                        font-weight: 600;
                    }
                    .item-7 {
                        cursor: pointer;
                        display: inline-block;
                        width: calc(100% / 7);
                        text-align: center;
                        height: 25px;
                        line-height: 25px;
                        float: left;
                        .select-day {
                            background: #3B4FBF;
                            width: 25px;
                            display: inline-block;
                            border-radius: 100px;
                            color: #fff;
                        }

                        .block-day {
                            background: #E1D8E9;
                            width: 100%;
                            display: inline-block;
                            color: #000;
                            cursor: default;
                        }
                    }
                }
            }
        }


        .rate-1-3 {
            width: 33%;
            display: inline-block;
            float: left;
        }
        .rate-2-3 {
            width: 66%;
            display: inline-block;
            float: left;
        }





        .item-form {


            &.mid {
                .el-select {
                    width: calc(50% - 15px);
                }
                .input-default {
                    width: calc(50% - 15px);
                }
            }
            &.dob {
                .el-select {
                    width: calc(50% - 10px);
                    margin: 0 10px 0 0;
                    .el-input {
                        input {
                            padding-left: 10px;
                            padding-right: 20px;
                            font-size: 12px;
                        }
                    }
                }
                .input-default {
                    width: calc(50% - 10px);
                    margin: 0 10px 0 0;
                }
            }
            .el-select {
                width: calc(100% - 15px);
                margin: 0 15px 0 0;
                float: left;
            }
            .input-default {
                width: calc(100% - 15px);
                margin: 0 15px 0 0;
                float: left;
            }
        }
        div.box-ai{
            .item-form {

                width: 50%;
                display: inline-block;
                .el-select {
                    width: calc(100% - 15px);
                    margin: 0 5px 0 0;
                    float: left;
                }
                .input-default {
                    width: calc(100% - 5px);
                    margin: 0 5px 0 0;
                    float: left;
                }
            }

        }

        .rate-1-5 {
            width: 15%;
            display: inline-block;
            float: left;
            &.dob {
                width: 20%;
            }
        }
        .rate-1-4 {
            width: 20%;
            display: inline-block;
            float: left;
        }
        .h-section {
            margin: 25px 0 0;
            display: block;
        }
        .box-head, .box-rate, .box-restrict,  .box-body {
            width: 100%;
            display: inline-block;
            float: left;
        }
        .box-body{
            &.pt-40{
                padding-top: 30px;

            }
            .row-css{
                margin-bottom: 20px;
                padding: 0 0 0 20px;
            }
        }
        .box-ai{
            label{
                float: left;
            }
            .item-form{

                float: left;
            }

        }
        .box-head {
            margin-top: 15px;
            label {
                font-size: 14px;
                margin-bottom: 0;
            }
        }
        .box-item {
            display: inline-block;
            width: 100%;
        }

        .form-check .form-check-label {
            text-transform: none;
        }
        .el-select.class-disabled .el-input.is-disabled .el-input__inner{
            background-color: #F5F5F5;
        }
        .big-size{
            .size-12{
                flex: 0 0 12.666667%;
                max-width: 12.666667%;
            }
        }
    }
    label.year-header{
        font-size: 22px;
        font-weight: 600;
    }
</style>
